import { setupFeatureFlags } from '@jumpcloud/ui-feature-flags';
import Vue from 'vue';

export const {
  FeatureFlagMixin,
  FeatureFlagsModule,
  initializeFlags,
  useFeatureFlags,
} = setupFeatureFlags([
  {
    localName: 'deviceTrust',
    sourceId: '2024-03-05-mobile-device-trust',
  },
  {
    localName: 'passwordManager',
    sourceId: '2022-05-25-Password-Manager-Toggle',
  },
  {
    localName: 'saasManagement',
    sourceId: '2024-06-12-saas-management',
  },
  {
    localName: 'synthesizedReports',
    sourceId: '2021-04-01-synthesized-reports',
  },
  {
    localName: 'workflowsManagement',
    sourceId: '2024-07-15-request-approval-workflow',
  },
]);

Vue.mixin(FeatureFlagMixin);
